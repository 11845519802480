import React from "react"
import { Link } from "gatsby"
import Layout from '../components/Layout'
import Seo from "../components/Seo"

const Error = () => {
  return (
    <Layout>
      <Seo title="Error" description="Error Page" />
    <main className="error-page">
      <div className="error-container">
        <h1>Dead End.</h1>
        <Link to='/' className="btn">
          back home
        </Link>
      </div>
    </main>
    </Layout>
  )
}

export default Error
